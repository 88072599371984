$primaryColor: #0511F2;
$primaryTransparentColor: #0511F233;
$secondaryColor: #FF4136;
$whiteColor: #FFFFFF;
$blackColor: #000000;
$blackDarkColor: #00000080;
$blackXDarkColor: #000000B2;
$blackLightColor: #00000055;
$size20: 20px;


$lightFont: 'TTSupermolot-Light';
$regularFont: 'TTSupermolot-Regular';
$boldFont: 'TTSupermolot-Bold';

@media (min-width: 1400px) {

  body {
    min-height: 100vh;
  }
  body {
    min-height: -webkit-fill-available;
  }

  html {
    height: -webkit-fill-available;
  }

  main {
    height: 100vh;
    height: -webkit-fill-available;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .main-content {
    width: 82%;
  }

  .header-nav {
    height: 75px !important;
    justify-content: space-between !important;
  }

  .setup-account {
    height: 60px !important;
    background-color: $whiteColor !important;
    margin-top: 7px !important;
  }

  .setup-account-typography {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: $blackColor;
    padding: 18px 50px !important;
  }

  .home-container {
    margin-top: 10px;
  }

  .setup-account-link {
    font-family: $boldFont, serif !important;
    font-size: 22px !important;
    font-weight: 400;
    line-height: 20px !important;
    color: $primaryColor;
    margin-left: 10px !important;
  }

  .row-chart {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
  }

  .col-1-chart {
    width: 70%;
  }

  .col-2-chart {
    width: 30%;
    padding-left: 10px;
    padding-right: 10px;
  }


  .statistic-card {
    border-radius: 0 !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }

  .wide-statistic-card {
    border-radius: 0 !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    height: 275px !important;
  }

  .info-svg {
    width: $size20;
    float: right;
  }

  .currency-svg {
    width: 30px;
    height: 30px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .arrow-chart-svg {
    width: 30px;
    height: 30px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .chart-card-value {
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
  }

  .chart-card-value-subtitle {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17.75px !important;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 25px !important;
  }

  .chart-card-views-title {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 5px !important;
    margin-left: 10px !important;
    display: inline-block;
    margin-top: 5px !important;
  }

  .chart-card-views-subtitle {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;
    color: rgba(0, 0, 0, 0.5);
    margin-left: 10px !important;
    display: inline-block;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    float: right;
  }

  .chart-card-title {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px !important;
  }

  .mobile-icon {
    margin-top: -10px !important;
    width: 15px;
  }
  .desktop-icon {
    margin-top: -10px !important;
    width: 20px;
  }

  .statistic-card {
    height: 275px !important;
  }

  .statistic-card .card-body {
    padding-bottom: 0px !important;
  }

  .statistic-card-title {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    margin-left: 25px !important;
  }

  .home-status {
    margin-top: 50px !important;
    background-color: $whiteColor !important;
    display: flex;
    justify-content: space-between;
  }

  .vertical-line-home-status {
    height: 200px !important;
    justify-content: center;
    margin-top: 40px !important;
  }

  .optimization-status {
    width: 49%;
  }

  .optimization-status-typography {

    font-family: $regularFont, serif !important;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    margin-bottom: 5px !important;
    margin-top: 25px !important;
    /*margin-left: 25px!important;*/
  }

  .optimization-status-button-title {

    font-family: $regularFont, serif !important;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 24.6px !important;
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    display: inline-block;
    margin-left: 5px !important;
  }

  .optimization-status-button-start-title {
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: $whiteColor !important;
    text-align: center;
    display: inline-block;
    margin-left: 10px !important;
  }

  .buttons-row {
    text-align: center;
    padding: $size20;
  }

  .optimization-status-button {
    background-color: rgba(209, 209, 209, 0.3) !important;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: $size20 !important;
    padding: 5px 0 !important;
    width: 100%;
  }

  .optimization-status-button-start {
    background-color: rgba(5, 17, 242, 1) !important;
    margin-top: 25px !important;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px !important;
    padding-right: 25px !important;
    width: 100% !important;
  }

  .circle-button-before-title {
    margin-top: -2px;
    width: 15px !important;
  }

  .performance-min-button {
    width: 20% !important;
    border-radius: 50px 0 0 50px !important;
    background-color: #DA0D0D !important;
    border-color: #DA0D0D !important;
    height: 40px !important;

  }

  .performance-min-button-title {
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: $whiteColor !important;
  }

  .performance-low-button {
    width: 20% !important;
    border-radius: 0 !important;
    background-color: #F21818 !important;
    border-color: #F21818 !important;
    height: 40px !important;

  }

  .performance-low-button-title {
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: $whiteColor !important;
  }

  .performance-mid-button {
    width: 20% !important;
    border-radius: 0 !important;
    background-color: #FFE601 !important;
    border-color: #FFE601 !important;
    height: 40px !important;

  }

  .performance-mid-button-title {
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: $whiteColor !important;
  }

  .performance-high-button {
    width: 20% !important;
    border-radius: 0 !important;
    background-color: #7FBA44B2 !important;
    border-color: #7FBA44B2 !important;
    height: 40px !important;

  }

  .performance-high-button-title {
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: $whiteColor !important;
  }

  .performance-max-button {
    width: 20% !important;
    background-color: #7FBA44 !important;
    border-color: #7FBA44 !important;
    height: 40px !important;
    border-radius: 0 50px 50px 0 !important;

  }

  .performance-max-button-title {
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: $whiteColor !important;
  }

  .arrow-top {
    margin-top: 10px !important;
    width: $size20 !important;
  }

  .arrow-top-hidden {
    visibility: hidden;
  }

  .performance-percentage-value {
    border: 2px solid #FFE601;
    background-color: rgba(209, 209, 209, 0.3);
    width: 20% !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px !important;
    padding-right: 5px !important;
    border-radius: $size20;
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.6) !important;
    margin-top: 10px !important;
  }

  .performance-percent {
    text-align: -webkit-center;
    margin-top: 10px !important;
  }

  .main-metrics-title {

    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    margin-left: 25px !important;
  }

  .main-metrics-select {

    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    margin-right: 25px !important;
    width: 50% !important;
    float: right
  }

  .main-metrics {
    margin-top: 50px !important;
    background-color: $whiteColor !important;
    justify-content: space-between;
  }

  .custom-select {
    position: relative;
    display: inline-block;
  }

  .custom-select__single-value::after,
  .custom-select__indicator-separator {
    display: none;
  }

  .select-icon {
    margin-right: 25px;
  }

  .main-metrics-select .custom-select__control {
    border-style: none;
  }

  .main-metrics-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .metrics-card {
    height: 140px !important;
    border-radius: 5px !important;
    width: 100% !important;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
  }

  .metrics-card-body {
    text-align: center
  }

  .metrics-card-svg {
    width: 25px !important;
  }

  .metrics-card-body-typography {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 15px !important;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 10px !important;
  }

  .metrics-card-body-typography-number {
    font-family: $regularFont, serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 1);
    margin-top: 10px !important;
  }

  .metrics-card-body-typography-number span {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 10px !important;
  }


  .home-datatable-div {
    /*background-color: $whiteColor!important;*/
    margin-top: 25px !important;
  }

  .home-data-table-title {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-bottom: 25px !important;
    margin-top: 25px !important;
    margin-left: 25px !important;
  }

  .p-input-icon-left input {
    font-family: $regularFont, serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 15px !important;
  }

  .home-datatable-div .p-datatable-header {
    background-color: $whiteColor !important;
  }

  .home-datatable-div .p-datatable-wrapper {
    /*background-color: $whiteColor!important;*/
  }

  .home-datatable-div .p-datatable-wrapper table {
    border-collapse: separate;
    border-spacing: 10px;
  }

  .home-datatable-div .p-datatable-wrapper table thead th {
    background-color: $whiteColor !important;
    font-family: $boldFont, serif !important;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 400;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }

  .datatable-icon {
    width: 17px;
  }

  .datatable-icon-chevrons {
    width: 12px;
  }

  .datatable-action-button {
    border: 1px solid rgba(209, 209, 209, 1) !important;
    border-radius: $size20 !important;
    font-family: $regularFont, serif !important;
    font-weight: 400 !important;
    font-size: 9px !important;
    line-height: 22.14px !important;
    background-color: $whiteColor !important;
    color: rgba(0, 0, 0, 0.5) !important;
    padding: 5px 4px !important;

  }

  .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: rgba(41, 214, 131, 1) !important;
  }

  .datatable-switch {
    width: 60px
  }

  .datatable-switch.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(1.8rem);
  }


  .mobile-progress-bar {
    width: 100% !important;
  }

  .desktop-progress-bar {
    width: 100% !important;
  }

  .optimization-progress-bar {
    width: 100%;
    margin-top: 65px;
    margin-bottom: 45px;
  }

  .chart-svg-home {
    width: 100%;
  }

  .vr-optimization {
    height: 100% !important
  }

  .term-title-typography {
    color: $blackColor;
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    margin-top: 25px !important;
  }

  .term-desc-typography {
    color: $blackColor;
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 17px !important;
  }

  .status-span {
    font-family: $regularFont, serif !important;
  }

  .nav-dash {
    place-self: center;
    text-align: -webkit-center;
    font-size: 30px;
    width: 2% !important;
    margin-left: -15px;
    margin-right: 5px;
  }

  .filters-inputs-div {
    margin-left: 25px !important;
  }

  .filters-input {
    margin-right: 10px !important;
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    height: 40px !important;
    /*color:rgba(0, 0, 0, 0.5);*/
  }

  .filters-date-picker {
    margin-right: 10px !important;
    font-family: $regularFont, serif !important;
    font-size: 25px !important;
    height: 0 !important;
    /*color:rgba(0, 0, 0, 0.5);*/
  }

  .filters-date-picker button {
    //background-color: $whiteColor !important;
    //color: $blackLightColor !important;
    //border: 1px solid #dee2e6 !important;
    //border-radius: 5px !important;
    //bottom: 35px;
    //height: 50px;

  }

  .filters-date-picker button svg {
    width: 25px;
    height: 25px;
  }

  .filters-input::placeholder {
    color: rgba(0, 0, 0, 0.5) !important;
    opacity: 1; /* Firefox */
  }

  .filters-input::-ms-input-placeholder { /* Edge 12 -18 */
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .filter-select {

    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 27.06px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    width: 30% !important;
    float: right
  }

  .custom-select {
    position: relative;
    display: inline-block;
  }

  .custom-select__single-value::after,
  .custom-select__indicator-separator {
    display: none;
  }

  .select-icon {
    margin-right: 5px;
  }

  .filter-select .custom-select__control {
    border-style: none;
  }

  .filter-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .logout {
    margin-right: 50px !important;
    width: 30%;
    display: flex;
    justify-content: space-around;
  }

  .nav-username {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    line-height: 19px !important;
    /*margin-right:10px !important;*/
    color: rgba(0, 0, 0, 0.7);
    margin: 15px !important;
  }

  .nav-username span {
    font-family: $regularFont, serif !important;
    font-size: 17px !important;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 0.5)
  }

  #nav-popup_menu_right_list {
    padding: 0 !important;
    margin: 0 !important;
  }

  #nav-popup_menu_right_list a {
    text-decoration: none
  }

  .datatable-edit-button {
    background-color: rgba(26, 128, 187, 0.5) !important;
    border: 1px solid rgba(26, 128, 187, 0.5) !important;
    border-radius: 5px !important;
    width: 28.07px !important;
    height: 27.49px !important;
  }

  .datatable-edit-button span {
    font-size: 15px !important;
  }

  .datatable-copy-button {
    background-color: rgba(70, 181, 141, 0.5) !important;
    border: 1px solid rgba(70, 181, 141, 0.5) !important;
    border-radius: 5px !important;
    width: 28.07px !important;
    height: 27.49px !important;
  }

  .datatable-copy-button span {
    font-size: 15px !important;
  }

  .datatable-calendar-button {
    background-color: rgba(255, 230, 1, 1) !important;
    border: 1px solid rgba(255, 230, 1, 1) !important;
    border-radius: 5px !important;
    width: 28.07px !important;
    height: 27.49px !important;
  }

  .datatable-calendar-button span {
    font-size: 15px !important;
  }

  .datatable-trash-button {
    background-color: rgba(244, 49, 72, 1) !important;
    border: 1px solid rgba(244, 49, 72, 1) !important;
    border-radius: 5px !important;
    width: 28.07px !important;
    height: 27.49px !important;
  }

  .datatable-trash-button span {
    font-size: 15px !important;
  }

  .export-button {
    background-color: rgba(5, 17, 242, 1) !important;
    font-family: $boldFont, serif !important;
    font-size: 16px !important;
    line-height: 15px !important;
    height: 35px !important;
    border-radius: 5px !important;
  }

  .datatable-header-buttons-div {
    display: flex;
    width: 25%;
    justify-content: space-around;
  }

  .filter-div {
    margin-top: 15px !important;
  }


  .filter-div ul .p-tabview-ink-bar {
    visibility: hidden !important;
  }
  .filter-div ul a {
    font-family: $regularFont, serif !important;
    text-decoration: none !important;
    color: rgba(0, 0, 0, 0.5) !important;

  }
  .filter-div ul .p-tabview-selected a {
    color: rgba(0, 0, 0, 1) !important;
    border-color: rgba(0, 0, 0, 1) !important
  }
  .search-campaign-input-span {
    height: 48px;
    width: 100%;
  }
  .search-campaign-input {
    height: 48px;
    width: 100%;
  }
  .campaign-filter-select {

    font-family: $regularFont, serif !important;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 27.06px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    height: 48px;
  }
  .search-campaign-div {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }


  .campaign-filter-select .custom-select__control {
    border-style: none;
  }

  .campaign-filter-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .tabview-div {
    width: 100%;
  }

  .pause-campaign-modal {
    width: 60%;
  }

  .pause-campaign-submit-button {
    margin: 8px 0px !important;
    background-color: $primaryColor !important;
    margin-bottom: $size20 !important;
    font-family: $boldFont, serif !important;
    text-transform: none !important;
    font-size: $size20 !important;
    width: 20% !important;
    border-radius: 0 !important;
    margin-right: 25px !important;
  }

  .pause-campaign-cancel-button {
    margin: 8px 0px !important;
    background-color: $whiteColor !important;
    border: 1px solid $primaryColor !important;
    border-radius: 0 !important;
    margin-bottom: $size20 !important;
    font-family: $boldFont, serif !important;
    text-transform: none !important;
    font-size: $size20 !important;
    width: 20% !important;
  }

  .revenue-filter-label {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    color: rgba(0, 0, 0, 0.5) !important;
    display: inline-block;
    position: relative;
    left: 50px !important;
    top: 10px !important;
    width: 1% !important;
    z-index: 2
  }
  .campaign-revenue-select {
    width: 70% !important;
    margin-right: 0 !important;
    display: inline-block !important;
  }

  .campaign-revenue-select .custom-select__placeholder {
    margin-left: 125px !important;
  }

  .campaign-revenue-select .custom-select__single-value {
    margin-left: 125px !important;
  }
  .campaign-revenue-number-select {
    width: 20% !important;
    display: inline-block !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
  }
  .sub-filters-hide {
    display: none !important;
  }
  .date-filter {
    width: 50%;
    position: relative;
    right: 0;
    left: 50%;
    top: 25px;
    z-index: 95;
    height: $size20;
  }
  .date-filter-select {

    font-family: $regularFont, serif !important;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 27.06px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    height: 48px;
  }


  .date-filter-select .custom-select__control {
    border-style: none;
  }

  .date-filter-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .date-picker-filter {
    font-family: $regularFont, serif !important;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 27.06px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    /*width: 70% !important;*/
    float: left;
    height: 48px;
  }
  .date-picker-filter button {
    background-color: transparent !important;
    border-left: none !important;
    border-color: var(--bs-border-color) !important;
    border-radius: 0 5px 5px 0 !important;

  }
  .date-picker-filter input {
    border-right: none !important;

  }
  .date-picker-filter button svg {
    color: $blackColor;

  }

  .breadcrumb {
    background-color: transparent;
    border: none;
    margin-top: 10px;
  }
  .breadcrumb a {
    text-decoration: none
  }
  .breadcrumb ol {
    padding-left: 0 !important;
  }
  .breadcrumb a span {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }
  .active-breadcrumb {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(5, 17, 242, 1)
  }

  .campaign-detail-title {
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    padding-top: 25px
  }

  .campaign-detail-label {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }

  .campaign-detail-value {
    font-family: $boldFont, serif !important;
    font-size: 17px !important;
    font-weight: 400;
    line-height: 16px !important;
  }
  .campaign-detail-boxes-container {
    margin-top: 10px;
  }

  .details-card {
    width: 90%;
    /*margin-left:5%*/
  }
  .details-col {
    margin-bottom: 10px !important;
  }

  .campaign-detail-card-title {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    width: 70%;
    display: inline-block;
  }

  .campaign-detail-card-value {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(5, 17, 242, 1) !important
  }

  .campaign-detail-card-svg {
    float: right;
    margin-right: 25px;
    margin-top: 10px;
    width: 25px;
  }

  .campaign-detail-card-click-svg {
    float: right;
    margin-right: 25px;
    margin-top: 10px;
    width: 17px;
  }

  .campaign-detail-card-percent {
    font-family: $regularFont, serif !important;
    font-size: 25px !important;
    font-weight: 400;
    line-height: 24px !important;
    color: rgba(21, 213, 255, 1) !important;
    float: right;
    margin-right: 25px;
    margin-top: 10px;
  }

  .preview-newsletter-button {
    background-color: rgba(5, 17, 242, 1) !important;
    font-family: $boldFont, serif !important;
    font-size: 15px !important;
    line-height: 14px !important;
    height: 40px !important;
    width: 90%;
    margin-left: 10% !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .targeted-customer-button {
    background-color: rgba(5, 17, 242, 1) !important;
    font-family: $boldFont, serif !important;
    font-size: 15px !important;
    line-height: 14px !important;
    height: 40px !important;
    width: 90%;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .campaign-buttons-row {
    padding-bottom: 100px !important;
  }

  .create-campaign-title {
    font-family: $boldFont, serif !important;
    font-size: 25px !important;
    line-height: 19px !important;
    color: $primaryColor;
    padding-top: 25px;
  }
  .create-campaign-image-div {
    text-align: center;
  }

  .create-campaign-image {
    width: 217px;
    height: 281px;
  }
  .create-campaign-label {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    line-height: 36.9px !important;
    color: $blackColor;
    padding-top: 25px;
  }
  .create-campaign-container {
    padding-bottom: 100px !important;
    background-color: $whiteColor !important;
  }

  .create-campaign-type-select {

    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 25px !important;
    width: 100% !important;
    margin-left: 15px;
    /*width: 70% !important;*/
    float: left;
    height: 40px;
  }

  .p-treeselect-panel .p-treeselect-header button {
    display: none;
  }


  .create-campaign-type-select .custom-select__control {
    border-style: none;
    margin-top: 10px !important;
  }

  .create-campaign-type-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .create-campaign-input {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    margin-top: 10px !important;
    margin-bottom: 25px !important;
    width: 100% !important;
    margin-left: 15px !important;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    height: 40px !important;
  }
  .create-campaign-input .MuiInputBase-root {
    height: 100% !important;
  }
  .create-campaign-input input::placeholder {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }
  .create-campaign-input input {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }

  .create-campaign-button {
    background-color: rgba(5, 17, 242, 1) !important;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px
  }
  .create-campaign-type-select .p-treeselect-label-container {
    width: 90%;
    display: inline-block;
  }
  .create-campaign-type-select .p-treeselect-trigger {
    width: 10%;
    display: inline-block;
    margin-top: 10px;
  }
  .create-campaign-type-select .p-treeselect-trigger svg {
    width: 18px;
    height: 18px;
  }
  .p-treenode-label {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }
  .create-campaign-user-select {

    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    margin-top: 10px !important;
    margin-bottom: 25px !important;
    width: 100% !important;
    margin-left: 15px;
    /*width: 70% !important;*/
    float: left;
    height: 100px;
  }


  .create-campaign-user-select .custom-select__control {

    font-family: $regularFont, serif !important;
    border-style: none;
    margin-top: 10px !important;
  }

  .create-campaign-user-select .custom-select__control--is-focused {

    font-family: $regularFont, serif !important;
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .create-campaign-user-select .p-multiselect-label {

    font-family: $regularFont, serif !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }
  .push-selected {
    display: none !important;
  }
  .campaign-detail-container {
    background-color: $whiteColor;
    margin-top: 10px !important;
  }
  .email-content-title {
    font-family: $lightFont, serif !important;
    font-size: 20px !important;
    line-height: 17px !important;
    color: $blackColor;
  }
  .back-svg {
    width: 12px;
    height: 12px;
    margin-top: 2px;
    margin-left: 10px;
  }
  .email-content-button {
    color: rgba(5, 17, 242, 1) !important;
    background-color: $whiteColor;
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    line-height: 24.7px !important;
    height: 53px !important;
    width: 90%;
    border-radius: 10px !important;
    margin-top: 1rem !important;
  }
  .email-content-built-in-button {
    color: $whiteColor !important;
    background-color: #3498DB80;
    font-family: $boldFont, serif !important;
    font-size: 15px !important;
    line-height: 24.7px !important;
    height: 40px !important;
    width: 90%;
    border-radius: 10px !important;
    border-color: #3498DB80;
    margin-top: 1rem !important;
  }

  .button-badge {
    position: relative;
    top: 30px;
    left: 0px;
    z-index: 2;
    font-size: 15px !important;
  }
  .email-content-save-design-button {
    color: $whiteColor !important;
    background-color: #54D5B6B2;
    font-family: $boldFont, serif !important;
    font-size: 15px !important;
    line-height: 24.7px !important;
    height: 40px !important;
    width: 100%;
    border-radius: 10px !important;
    border-color: #3498DB80;
    margin-top: 3.3rem !important;
  }
  .email-content-reset-design-button {
    color: $whiteColor !important;
    background-color: #ADD8E6;
    font-family: $boldFont, serif !important;
    font-size: 15px !important;
    line-height: 24.7px !important;
    height: 40px !important;
    width: 100%;
    border-radius: 10px !important;
    border-color: #3498DB80;
    margin-top: 3.3rem !important;
  }
  .email-content-additional-info-button {
    color: $whiteColor !important;
    background-color: #D3D3D3;
    font-family: $boldFont, serif !important;
    font-size: 15px !important;
    line-height: 24.7px !important;
    height: 40px !important;
    width: 100%;
    border-radius: 10px !important;
    border-color: #3498DB80;
    margin-top: 3.3rem !important;
  }
  .email-content-buttons-row {
    margin-bottom: 75px !important;
  }

  .email-content-header {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .email-content-input-label {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 17px !important;
  }
  .required {
    color: red !important;
  }

  .email-content-input {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    width: 100% !important;
    margin-left: 15px !important;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    height: 40px !important;
  }
  .email-content-input .MuiInputBase-root {
    height: 100% !important;
  }
  .email-content-input input::placeholder {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 17px !important;
  }
  .email-content-input input {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 17px !important;
  }

  .email-content-editor {
    height:1080px!important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .email-content-editor-buttons {
    margin-top: 50px !important;
    padding-bottom: 50px !important;
  }

  .email-content-view-source-code-button {
    color: #00000980 !important;
    background-color: $whiteColor;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    width: 75%;
    border-radius: 10px !important;
    border-color: #D1D1D1;
    margin-top: 1rem !important;
    margin-left: 25px;
  }

  .email-content-send-example-button {
    color: $whiteColor !important;
    background-color: $primaryColor;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    width: 100%;
    border-radius: 10px !important;
    border-color: #D1D1D1;
    margin-top: 1rem !important;
    margin-left: 25px;
  }

  .email-content-send-button {
    color: $whiteColor !important;
    background-color: $primaryColor;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    width: 80%;
    border-radius: 10px !important;
    border-color: #D1D1D1;
    margin-top: 1rem !important;
    margin-left: 25px;
  }

  .push-content-upload-button {
    color: $whiteColor !important;
    background-color: $primaryColor;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    width: 90%;
    border-radius: 10px !important;
    border-color: #D1D1D1;
    margin-top: 1rem !important;
    margin-bottom: 3rem !important;
    margin-left: 25px;
  }
  .push-content {
    margin-left: $size20 !important;
  }

  .push-content-input {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 15px !important;
    margin-top: 25px !important;
    width: 85% !important;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    height: 40px !important;
    border-radius: 10px !important;
  }

  .push-content-switch {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 15px !important;
    margin-top: 25px !important;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    border-radius: 10px !important;
  }
  .push-content-input .MuiInputBase-root {
    height: 100% !important;
    border-radius: 10px !important;
  }
  .push-content-input input::placeholder {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }
  .push-content-input input {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }

  .push-content-text-area {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 15px !important;
    margin-top: 25px !important;
    width: 85% !important;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    min-height: 120px !important;
    height: 120px;
    padding-left: $size20 !important;
    padding-top: 10px !important;
    border-radius: 10px !important;
  }
  .push-content-text-area .MuiInputBase-root {
    height: 100% !important;
    border-radius: 10px !important;
  }
  .push-content-text-area textarea::placeholder {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }
  .push-content-text-area textarea {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }
  .character-count {
    font-family: $lightFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 18.45px !important;
    margin-top: 38px !important;
    margin-bottom: 70px !important;
    display: flex;
    justify-content: space-evenly;
  }
  .character-count-text-area {
    font-family: $lightFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 18.45px !important;
    margin-top: 120px !important;
    display: flex;
    justify-content: space-evenly;
  }

  .optional {
    color: $blackDarkColor;
    margin-left: 5px !important;
  }
  .product-uploader {
    width: 90%;
    height: 250px;
    border: 1px solid #D1D1D1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-bottom: 97px;
  }
  .push-content-product-uploader-title {
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 17px !important;
    color: $blackColor;
    padding: 20px;
    width: 100%;
    text-align: left;

  }

  .push-content-product-uploader-svg {
    width: 70px;
    height: 70px;
  }

  .push-content-product-uploader-label {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: $blackXDarkColor;
    width: 100%;
    text-align: left;
    float: left;
  }
  .push-content-product-uploader-title-div {
    margin-bottom: 10%;
  }

  .push-content-submit-row {
    justify-content: center;
  }

  .push-content-send-button {
    color: $whiteColor !important;
    background-color: $primaryColor;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17.17px !important;
    height: 40px !important;
    border-radius: 10px !important;
    border-color: #D1D1D1;
    margin-top: 3.3rem !important;
    margin-bottom: 25px !important;
    margin-left: 25px;
    justify-content: center;
  }
  .push-content-send-button span {
    flex: none !important;
    margin-right: 10px;
  }

  .facebook-account-select {

    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    margin-top: 1rem !important;
    margin-right: 1rem !important;
    padding: 5px 0.75rem !important;
  }

  .facebook-account-select .custom-select__control {
    border-style: none;
  }

  .facebook-account-select svg {
    width: 13px;
    height: 13px;
    margin-top: 5px;
  }

  .facebook-account-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .configuration-page-select {

    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 30.75px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    width: 95% !important;
    float: left;
    display: inline-block !important;
    height: 45px;
    padding:0!important;
  }

  .configuration-page-select .custom-select__control {
    border-style: none;
  }

  .configuration-page-select svg {
    width: 13px;
    height: 13px;
    margin-top: 0px;
  }

  .configuration-page-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .facebook-campaign-setup-title {
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: $primaryColor;
  }

  .facebook-content-input {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    width: 95% !important;
    margin-left: 15px !important;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    height: 40px !important;
  }
  .facebook-content-input .MuiInputBase-root {
    height: 100% !important;
    border-radius: 5px !important;
  }
  .facebook-content-input input::placeholder {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 30.75px !important;
  }
  .facebook-content-input input {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }

  .facebook-status-select {

    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    width: 95% !important;
    float: left;
    display: inline-block !important;
    margin-top: 1rem !important;
    margin-bottom: 50px !important;
    margin-right: 1rem !important;
    padding: 5px 0.75rem !important;
  }

  .facebook-status-select .custom-select__control {
    border-style: none;
  }

  .facebook-status-select svg {
    width: 13px;
    height: 13px;
    margin-top: 5px;
  }

  .facebook-status-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .facebook-cancel-button {
    color: $blackColor !important;
    background-color: #D1D1D180;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    width: 90%;
    border-radius: 10px !important;
    border-color: #D1D1D1;
    margin-top: 3.3rem !important;
    margin-left: 25px;
  }

  .facebook-create-button {
    color: $whiteColor !important;
    background-color: $primaryColor;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    width: 80%;
    border-radius: 10px !important;
    border-color: #D1D1D1;
    margin-top: 3.3rem !important;
    margin-left: 25px;
  }

  .horizontal-hr-facebook {
    border-top: 2px solid #d1d1d1 !important;
    opacity: 1 !important;
  }

  .facebook-datatable-div {
    /*background-color: $whiteColor!important;*/
    margin-top: 25px !important;
  }

  .facebook-datatable-div .p-datatable-header {
    background-color: $whiteColor !important;
  }

  .facebook-datatable-div .p-datatable-wrapper table {
    border: 1px solid #D1D1D1 !important;
  }

  .facebook-datatable-div .p-datatable-wrapper table thead th, td {
    background-color: $whiteColor !important;
    border-bottom: 1px solid #D1D1D1 !important;
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    letter-spacing: 0em !important;
    text-align: left !important;

  }

  .facebook-datatable-div .p-datatable-wrapper table thead th {
    background-color: #D1D1D180 !important;
    border-bottom: 1px solid #D1D1D1 !important;
  }

  .facebook-datatable-div .p-datatable-header {
    border: none !important;
  }

  .facebook-datatable-div .p-paginator {
    border: 1px solid #d1d1d1 !important;
    border-top: none !important;
  }

  .facebook-datatable-div .p-datatable-row-expansion td:first-child {
    padding: 0 !important;
  }

  .facebook-datatable-div .p-datatable-row-expansion td {
    margin-left: 25px !important;
  }

  .facebook-datatable-title {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: $primaryColor !important;
    margin-bottom: 25px !important;
  }

  .facebook-create-ads-set-button {
    background-color: transparent !important;
    border: none !important;
    color: $blackColor;
    margin-left: 15%;
  }

  .facebook-create-ads-set-button span {
    font-size: 18px;
  }
  .facebook-datatable-filter-div {
    display: flex;
    width: 45%;
    justify-content: space-around;
  }

  .facebook-search-campaign-input-span {
    height: 40px;
    width: 100%;
  }
  .facebook-search-campaign-input {
    height: 40px;
    width: 100%;
  }

  .facebook-campaign-filter-select {

    font-family: $regularFont, serif !important;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 27.06px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    /*width: 70% !important;*/
    float: left;
    display: inline-block !important;
    height: 41px;
    padding: 0 0.75rem !important;
  }


  .facebook-campaign-filter-select .custom-select__control {
    border-style: none;
  }

  .facebook-campaign-filter-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .adset-content-input-label {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }
  .facebook-campaign-start-date-title {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    color: $blackColor;
  }
  .facebook-campaign-start-date-calendar {
    width: 100%
  }
  .facebook-campaign-start-date-calendar button {
    background-color: $primaryColor !important;
  }
  .facebook-campaign-end-date-label {
    font-family: $regularFont, serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 19.68px !important;
    color: $blackColor;
  }
  .optional-end-date {
    font-family: $regularFont, serif !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.76px !important;
    color: $blackDarkColor;
    margin-left: 10px !important;
  }
  .facebook-genders-selected-buttons {
    font-family: $regularFont, serif !important;
    display: flex;
    justify-content: space-around;
  }
  .facebook-genders-selected-buttons .p-button {
    font-family: $regularFont, serif !important;
    border-right: 1px solid #d1d1d1 !important;
    border-radius: 5px !important;
  }
  .facebook-genders-selected-buttons .p-highlight:hover {
    font-family: $regularFont, serif !important;
    background-color: $primaryColor !important;
  }
  .facebook-genders-selected-buttons .p-highlight {
    font-family: $regularFont, serif !important;
    background-color: $primaryColor !important;
  }
  .facebook-age-range-label {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: 0em !important;
    text-align: left;

  }
  .facebook-campaign-detailed-title {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    letter-spacing: 0em;
    text-align: left;
    color: $blackDarkColor;
  }
  .facebook-campaign-interests-title {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 22px !important;
    letter-spacing: 0em;
    text-align: left;
    color: $blackColor;

  }

  .facebook-campaign-buttons {
    justify-content: center;
  }

  .pmax-select {

    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    width: 95% !important;
    float: left;
    padding: 0 !important;
    display: inline-block !important;
    height: 40px;
  }

  .pmax-select .custom-select__control {
    border-style: none;
  }

  .pmax-select svg {
    width: 13px;
    height: 13px;
    margin-top: 5px;
  }

  .pmax-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }


  .pmax-campaign-start-date-calendar {
    width: 95%
  }
  .pmax-campaign-start-date-calendar button {
    background-color: $primaryColor !important;
  }
  .email-content-header {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }

  .pmax-campaign-explicitly-Shred-label {
    font-family: $lightFont, serif !important;
    font-size: $size20;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

  }

  .pmax-assets-button {
    font-family: $regularFont, serif !important;
    font-size: $size20;
    font-weight: 400;
    line-height: 44px;
    width: 92px;
    height: 44px;
    border: 1px solid #D1D1D1;
    text-align: center;
    cursor: pointer;
    background-color: $whiteColor;
    color: $blackColor;
  }
  .pmax-button-label {
    font-family: $lightFont, serif !important;
    font-size: $size20;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
    margin-left: 10px;
    color: $blackColor;
  }
  .pmax-button-label-dimensions {
    color: #F21818
  }
  .pmax-container {
    padding-bottom: 25px !important;
  }

  .input_conv-goal-div {
    padding-top: 15px !important;
  }
  .input_conv-goal {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    width: 95% !important;
    margin-left: 15px !important;
    float: left;
    display: inline-block !important;
    height: 48px !important;
  }
  .input_conv-goal .MuiInputBase-root {
    height: 100% !important;
    border-radius: 5px !important;
  }
  .input_conv-goal input::placeholder {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;

  }
  .input_conv-goal input {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;
  }

  .facebook-campaign-setup .p-panel-header {
    background-color: $whiteColor !important;
    border: none !important;
    padding: 5px !important;
  }

  .facebook-campaign-setup .p-panel-header svg {
    width: 30px !important;
    height: 30px !important;
  }

  .facebook-campaign-setup .p-panel-header span {
    font-family: $regularFont, serif !important;
    font-size: 25px !important;
    font-weight: 400 !important;
    line-height: 31px !important;
    letter-spacing: 0em;
    text-align: left;

  }
  .shopping-locations-title {
    font-family: $regularFont, serif !important;
    font-size: 25px !important;
    font-weight: 400 !important;
    line-height: 31px !important;
    letter-spacing: 0em;
    text-align: left;
  }

  .facebook-campaign-setup .p-panel-content {
    border: none !important;
    padding: 0 !important;
  }

  .question-mark-icon {
    margin-left: 10px;
  }

  .help-increase-label {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 25px !important;
    letter-spacing: 0em;
    text-align: left;

  }
  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: $primaryColor !important;
    background: $primaryColor !important;
  }
  .p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: $primaryColor !important;
    background: $whiteColor !important;
  }
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {

    background-color: $primaryColor !important;
  }

  .location-options-button {
    background-color: $primaryTransparentColor !important;
    color: $primaryColor !important;
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 25px !important;
    letter-spacing: 0em;
    text-align: left;

  }

  .location-options-button .p-button-icon {
    font-size: 25px !important;
    color: $blackColor !important;
  }
  .input_roas {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    width: 90% !important;
    float: left;
    display: inline-block !important;
    height: 48px !important;
  }
  .input_roas .MuiInputBase-root {
    height: 100% !important;
    border-radius: 5px !important;
    width: 90% !important;
  }
  .input_roas input::placeholder {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;
    float: right;

  }
  .input_roas input {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;
    width: 90% !important;
  }

  .input_maximum-cost-per-click {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    width: 90% !important;
    float: left;
    display: inline-block !important;
    height: 48px !important;
  }
  .input_maximum-cost-per-click .MuiInputBase-root {
    height: 100% !important;
    border-radius: 5px !important;
    width: 90% !important;
  }
  .input_maximum-cost-per-click input::placeholder {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;

  }
  .input_maximum-cost-per-click input {
    font-family: $lightFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 24.6px !important;
    width: 90% !important;
  }

  .general-configuration-title {
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    text-align: left;
    color: $primaryColor !important;

  }

  .general-configuration-container {
    padding-bottom: 100px !important;
    /*background-color:$whiteColor!important;*/
    padding-top: 10px !important;
    margin-top: 10px;
  }

  .edit-configuration-button {
    background-color: $primaryColor !important;
    border-radius: 10px !important;
    width: 50px !important;
    height: 40px !important;

  }

  .submit-configuration-button {
    background-color: $primaryColor !important;
    border-radius: 10px !important;
    width: 123px !important;
    height: 40px !important;
    font-family: $regularFont, serif !important;

  }

  .cancel-configuration-button {
    background-color: $blackDarkColor !important;
    border-radius: 10px !important;
    width: 123px !important;
    height: 47px !important;

  }

  .general-configuration-value-label {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 17px !important;
    text-align: left;

  }

  .general-configuration-value-label span {
    font-family: $lightFont, serif !important;
    overflow-wrap: anywhere;
  }

  .email-test-configuration-button {
    background-color: $primaryColor !important;
    border-radius: 10px !important;
    width: 250px !important;
    height: 40px !important;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 17px !important;

  }

  .general-configuration-part {
    background-color: $whiteColor !important;
    width: 100% !important;
    margin-bottom: 15px !important;
  }


  .dropdown-toggle {
    outline: 0;
  }

  .btn-toggle {
    padding: .25rem .5rem;
    font-weight: 600;
    color: var(--bs-emphasis-color);
    background-color: transparent;
  }
  .btn-toggle:hover,
  .btn-toggle:focus {
    color: rgba(var(--bs-emphasis-color-rgb), .85);
    background-color: var(--bs-tertiary-bg);
  }

  .btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
  }

  [data-bs-theme="dark"] .btn-toggle::before {
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%28255,255,255,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  }

  .btn-toggle[aria-expanded="true"] {
    color: rgba(var(--bs-emphasis-color-rgb), .85);
  }
  .btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
  }

  .btn-toggle-nav a {
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
  }
  .btn-toggle-nav a:hover,
  .btn-toggle-nav a:focus {
    background-color: var(--bs-tertiary-bg);
  }

  .scrollarea {
    overflow-y: auto;
  }

  .nav-link {

    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    line-height: 30px;
    padding: 5px 0px !important;
  }

  .nav-link svg {
    margin-right: $size20;
  }

  .sidebar-svg {
    width: $size20;
    margin-right: $size20;
    stroke: $blackColor;
    stroke-width: 0;
  }
  .home-svg {
    width: $size20 !important;
  }

  .active .config-svg {
    fill: $whiteColor !important;
  }

  .sub-menu {
    margin-left: 0;
  }

  .active svg {
    fill: $primaryColor !important;
    stroke: $primaryColor !important;
  }

  .nav-link.active {
    background-color: transparent !important;
    color: $primaryColor !important;
  }


  .nav-link:has(> .nav-link.active) {
    color: $primaryColor !important;
    background-color: #0000001f!important;
  }


  li:has(> .nav-link.active) {
    color: $primaryColor !important;
    background-color: #0000001f!important;
  }


  //.nav-item:has(.nav-link.active) {
  //  color: $primaryColor !important;
  //  background-color: #0000001f!important;
  //}

  .expanded-arrow {
    float: right;
    margin-top: 8px;
    width: 24px !important;
    height: 24px !important;
  }

  .sub-menu li a {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 28px !important;
    color: $blackColor;
  }

  .sub-menu li  {
    padding-left:20%
  }

  .nav-item{
padding-left:20px
  }
  .sidebar {
    width: 18%;
  }

  .sidebar-nav {
    color: $blackColor !important;
  }

  .logo-container {
    padding-top: 50px;
    margin-bottom: 100px
  }

  .logo-image {
    width: 150px;
    margin-right: $size20;
  }

  .right {
    text-align: right;
  }

  .have-account-typography {
    font-family: 'TTSupermolot-Regular', serif !important;
    font-size: $size20 !important;
  }

  .login-header {
    margin-left: 4px;
    font-weight: 400;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: $size20 !important;
  }

  .register-container {
    margin-top: 200px;
    margin-bottom: 200px
  }

  .email-label {
    font-family: 'TTSupermolot-Regular', serif !important;
    font-size: 18px
  }

  .email-input {
    margin-bottom: 25px !important;
  }

  .email-verification-button {
    margin: 8px 0px !important;
    background-color: #0511f2 !important;
    margin-bottom: $size20 !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    text-transform: none !important;
    font-size: $size20 !important;
  }

  .register-description-typography {
    color: #828282 !important;
    font-family: 'TTSupermolot-Light', serif !important;
    font-size: 18px !important;
    text-align: center;
    line-height: 22.14px !important;
  }

  .privacy-policy-link {
    font-weight: 400;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: $size20;
    line-height: 24.7px
  }

  .vertical-line {
    height: 400px;
    justify-content: center
  }

  .social-login-title {
    text-align: center;
    margin-bottom: $size20 !important;
    font-weight: 400 !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: 25px !important;
    line-height: 24px !important;
  }

  .social-login-subtitle {
    color: #828282;
    text-align: center;
    margin-bottom: 50px !important;
    font-weight: 400 !important;
    font-family: 'TTSupermolot-Regular', serif !important;
    font-size: 20px !important;
    line-height: 19px !important;
  }

  .google-sign-in-btn {
    margin: 10px 0 !important;
    background-color: #ffffff !important;
    color: #000000 !important;
    margin-bottom: 25px !important;
    font-weight: 400 !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: 18px !important;
    line-height: 22.23px !important;
  }

  .configuration-google-sign-in-btn {
    margin: 10px 0 !important;
    background-color: #ffffff !important;
    color: #000000 !important;
    margin-bottom: 25px !important;
    font-weight: 400 !important;
    font-family: "TTSupermolot-Bold", serif !important;
    font-size: 18px !important;
    line-height: 22.23px !important;
    width: 100%;
    justify-content: center;
    border: 1px solid #000000;
    border-radius: 10px;
  }

  .google-icon {
    width: 25px;
    margin-right: $size20
  }

  .facebook-sign-in-btn {
    margin: 10px 0 !important;
    background-color: #3b5998 !important;
    color: #ffffff !important;
    margin-bottom: 25px !important;
    font-weight: 400 !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: 18px !important;
    line-height: 22.23px !important;
  }

  .facebook-icon {
    width: 25px;
    margin-right: $size20
  }

  .login-container-title {
    border: solid #0511F2 1px;
    padding: 25px !important;
  }

  .login-container-title-typography {
    color: #585555;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 19px !important;

  }

  .login-container-subtitle-typography {
    color: #828282;
    font-family: 'TTSupermolot-Regular', serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 17px !important;

  }

  .login-form-container {
    margin-top: 100px !important;
    margin-bottom: 200px !important;
  }

  .login-email-label {
    font-family: 'TTSupermolot-Regular', serif !important;
    font-size: 18px !important;
    margin-bottom: 10px !important;
  }

  .login-email-input {
    margin-bottom: 25px !important;
  }

  .login-email-input div {
    height: 40px
  }

  .login-checkbox-label {
    font-family: 'TTSupermolot-Regular', serif !important;
    font-size: $size20 !important;
    Font-weight: 400 !important;
  }

  .login-remember-label {
    font-family: 'TTSupermolot-Regular', serif !important;
    font-size: $size20 !important;
    Font-weight: 400 !important;
  }

  .login-forget-label {
    font-family: 'TTSupermolot-Regular', serif !important;
    font-size: $size20 !important;
    Font-weight: 400 !important;
    line-height: 24.6px !important;
    float: right !important;
    color: #3B3B3B !important;
    margin-top: 10px !important;

  }

  .login-submit-button {
    margin: 8px 0 !important;
    background-color: #0511f2 !important;
    margin-bottom: 25px !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    text-transform: none !important;
    font-size: $size20 !important;
    line-height: 24.7px !important;
    color: #ffffff !important;
  }

  .auth-logo-container {
    padding-top: 50px;
    margin-bottom: 50px
  }

  .authorization-title-div {
    text-align: center;
  }

  .authorization-title {
    color: #000000;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 19px !important;

  }

  .authorization-container {
    margin-top: 50px;
    margin-bottom: 50px
  }

  .check-icon {
    font-weight: bold;
    font-size: 25px !important;
    color: #0511f2 !important;
    margin-right: 15px;
    margin-top: 5px;
  }

  .mail-icon {
    font-size: 30px !important;
    color: #3B3B3B !important;
    margin-right: 40px;
  }

  .authorization-icon-label {
    font-family: 'TTSupermolot-Light', serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 15px !important;
    color: #000000;
  }

  .authorization-icon-box-label {
    font-family: 'TTSupermolot-Light', serif !important;
    font-weight: 400 !important;
    font-size: $size20 !important;
    line-height: 24.6px !important;
    color: #3B3B3B;
  }

  .authorization-icon-div {
    display: flex;
    justify-content: center;
  }

  .authorization-badge-col {
    text-align: center;
    margin-top: 40px !important;
    margin-bottom: 40px !important;

  }

  .authorization-badge {
    background-color: #D2D4F9 !important;
    padding: 5px 75px;
    border-radius: 50px;
    color: #0511F2;
    font-size: 18px;
    font-family: 'TTSupermolot-Light', serif !important;
    font-weight: 400 !important;
    line-height: 17px !important;
  }

  .authorization-mail-box {
    width: 90%;
    padding: $size20;
    border: 1px solid #D1D1D1;
  }

  .authorization-checkbox-label {
    font-family: 'TTSupermolot-light', serif !important;
    font-size: 18px !important;
    Font-weight: 400 !important;
    width: 90%;
    margin-top: 25px !important;
    color: #828282 !important;
  }

  .authorization-checkbox svg {
    font-size: 40px !important;
    color: #D1D1D1 !important;
  }

  .authorization-checkbox-label-span {
    font-family: 'TTSupermolot-light', serif !important;
    font-size: 18px !important;
    Font-weight: 400 !important;
    color: #828282 !important;
  }

  .verification-subtitle {
    font-size: 18px !important;
    Font-weight: 400 !important;
    color: #828282 !important;
    font-family: 'TTSupermolot-light', serif !important;
    line-height: 22.14px !important;
  }

  .verification-email {
    font-size: 18px !important;
    Font-weight: 400 !important;
    color: #000000 !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    line-height: 22.23px !important;
    margin-top: 25px !important;
  }

  .verification-code {
    font-size: 18px !important;
    Font-weight: 400 !important;
    color: #000000 !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    line-height: 22.23px !important;
    margin-top: 35px !important;
  }

  .verification-container {
    width: 881px !important;
  }

  .otp-input {
    width: 12%;
    border: none;
    border-bottom: 2px solid #0511F2;
    margin-right: $size20;
    margin-left: 15px;
    outline: none; /* Added to remove outline border */
  }

  .otp-input:focus {
    width: 12%;
    border: none !important;
    border-bottom: 2px solid #0511F2 !important;
    margin-right: $size20;
    margin-left: 15px;
  }

  .otp-input-container {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 100px;
  }

  .verification-button {
    width: 90% !important;
    margin: 8px 0px !important;
    background-color: #0511f2 !important;
    margin-bottom: 50px !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    text-transform: none !important;
    font-size: $size20 !important;
  }

  .verification-email-label {
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: $size20 !important;
    Font-weight: 400 !important;
    line-height: 32.11px !important;
    color: #828282 !important;
  }

  .verification-email-link {
    margin-left: 4px;
    font-weight: 400;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: $size20 !important;
    line-height: 32.11px !important;
    color: #0511F2 !important;
  }

  .create-account-title {
    color: #000000;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 67.93px !important;
    margin-bottom: 50px !important;
  }

  .create-account-username-label {
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: 18px !important;
    margin-bottom: 25px !important;
    margin-top: 10px !important;
    line-height: 17px !important;
    font-weight: 400 !important;
  }

  .create-account-password-label {
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: 18px !important;
    margin-bottom: 25px !important;
    margin-top: 10px !important;
    line-height: 17px !important;
    font-weight: 400 !important;
  }

  .create-account-form {
    margin-bottom: 50px !important;
  }

  .create-account-submit-button {

    margin: 8px 0 !important;
    background-color: #0511f2 !important;
    margin-bottom: 25px !important;
    margin-top: 15px !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    text-transform: none !important;
    font-size: $size20 !important;
    line-height: 19px !important;
    color: #ffffff !important;
  }

  .logo-container-fluid {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .basic-info-container {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }

  .basic-info-title {
    color: #000000;
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 19px !important;
    margin-bottom: 50px !important;
  }

  .uploader-text {
    text-align: center;
  }

  .acceptance-of-toc-checkbox-label {
    margin-top: 25px;
  }

  .acceptance-of-toc-label {
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: 18px !important;
    Font-weight: 400 !important;
    line-height: 17px !important;
    color: #000000 !important;
  }

  .toc-label {
    font-family: 'TTSupermolot-Bold', serif !important;
    font-size: 18px !important;
    Font-weight: 400 !important;
    line-height: 17px !important;
    margin-left: 5px !important;
    color: #0511F2 !important;
  }

  .acceptance-of-toc-checkbox-label svg {
    font-size: 25px !important;
  }

  .sidebar-logo-image {
    width: 150px !important;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .sidebar-logo-image-div {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .sidebar {
    background-color: #ffffff !important;
    height: auto;
  }
  .image-uploader {
    width: 100%;
    height: 328px;
    border: 1px solid #D1D1D1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
  }

  .modal {
    display: none; /* Hidden by default */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 9999; /* Ensure the modal appears on top of other elements */
  }

  .modal.open {
    display: block; /* Show the modal when open */
  }

  .modal-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: $size20;
    border-radius: 4px;
  }

  .forget-password-modal-content {
    border: none !important;
    padding: 50px 70px;
  }

  .send-verification-modal-content {
    border: none !important;
    padding: 50px 30px;
  }
  .forget-password-modal-container {
    text-align: center;
  }
  .forget-password-modal-icon {

  }

  .forget-password-modal-title-typography {
    font-family: 'TTSupermolot-Bold', serif !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 49.4px;
    color: rgba(0, 0, 0, 1);
    margin-top: $size20 !important;
  }

  .forget-password-modal-subtitle-typography {
    font-family: 'TTSupermolot-Regular', serif !important;
    font-weight: 400 !important;
    font-size: 25px !important;
    line-height: 30.75px;
    color: rgba(59, 59, 59, 1);
    margin-top: $size20 !important;
    margin-bottom: 25px !important;
  }

  .forget-password-modal-email-label {
    font-family: 'TTSupermolot-Bold', serif !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 37.05px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-top: 40px !important;
    margin-bottom: 15px !important;
  }

  .forget-password-modal-email-input {
    margin-bottom: 25px !important;
  }

  .forget-password-modal-button {
    margin: 8px 0px !important;
    background-color: #0511f2 !important;
    margin-bottom: $size20 !important;
    font-family: 'TTSupermolot-Bold', serif !important;
    text-transform: none !important;
    font-size: $size20 !important;
  }

  .mobile-sidebar {
    display: none !important;
  }

  .login-remember-label {
    font-size: 19px !important;
  }
  .login-forget-label {
    font-size: 19px !important;
  }
  .authorization-checkbox-label-span {
    font-size: 16px !important;
  }
  .image-uploader {
    width: 100%;
    height: 180px !important;
  }

  ////////////////////////////////////////////////////

  .location_search_div {
    margin-left: 35px !important;
    margin-top: 35px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: none !important;
  }

  .location_search_div_camp_settings {
    margin-top: 35px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: none !important;
  }

  .location_search_dropdown {
    width: 100% !important;
  }
  .search-for-location-input {
    width: 100% !important;
    height: 47px;
  }

  .search-for-location-dropdown {
    width: 130% !important;
    transform: translate3d($size20, 60.4444px, 0px) !important;
  }
  .search-for-location-dropdown-question-mark-icon {
    margin-left: 10px !important;
    width: $size20
  }
  .search-for-location-dropdown-header-reach {
    display: flex;
    justify-content: space-between;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .search-for-location-dropdown-header {
    font-family: $lightFont, serif !important;
    font-size: $size20;
    font-weight: 400;
    line-height: 24.6px;
    text-align: left;

  }

  .search-for-location-dropdown-country {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 24.6px !important;
    text-align: left !important;

  }

  .search-for-location-dropdown-country span {
    font-family: $regularFont, serif !important;
    font-size: $size20;
    font-weight: 400;
    line-height: 24.6px !important;
    text-align: left !important;
    color: $blackDarkColor !important;
    margin-left: 5px;
  }

  .search-for-location-dropdown-reach-number {
    font-family: $regularFont, serif !important;
    font-size: $size20;
    font-weight: 400;
    line-height: 24.6px !important;
    text-align: left !important;
    color: $blackDarkColor !important;
  }

  .search-for-location-dropdown-reach-link {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 24.6px !important;
    text-align: left !important;
    color: $primaryColor;

  }

  .search-for-location-dropdown-reach-link-selected {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 24.6px !important;
    text-align: left !important;
    color: $blackColor;

  }

  .search-for-location-dropdown-country-div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .dropdown-item-buttons {
    justify-content: space-between;
    width: 60%;
  }

  .loading-search-location {
    position: relative;
    left: 40%;
    color: #0511f2;

  }

  .ad-group-assets {
    margin-top: 50px !important;
    background-color: $whiteColor !important;
    justify-content: space-between;
  }

  .ad-group-links-div {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }

  //.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  //  color: var(--bs-btn-active-color);
  //  background-color: $primaryColor!important;
  //  border-color: $primaryColor!important;
  //}

  .button-group-ad-group {
    margin: 25px;
    float: right;
  }

  .ad-group-assets .p-datatable-header {
    background-color: $whiteColor;
  }

  .ad-group-assets table thead tr th {
    background-color: $whiteColor !important;
    font-family: $boldFont, serif !important;
    font-size: 14px !important;
    line-height: 13px !important;
    font-weight: 400;
  }

  .choose-account-container {
    height: 700px !important;
  }

  #bar-status .max {
    position: relative;
    left: 40%;
  }
  #bar-status .min {
    position: relative;
    left: -40%;
  }
  #bar-status .low {
    position: relative;
    left: -20%;
  }
  #bar-status .mid {
    position: relative;
    left: 0;
  }
  #bar-status .high {
    position: relative;
    left: 20%;
  }
  #bar-status > div {
    transition: 0.2s ease-out;
    left: 0;
  }
  #bar-status .max .performance-percentage-value {
    border: 2px solid #7FBA44;
  }
  #bar-status .high .performance-percentage-value {
    border: 2px solid #7fba44b2;
  }
  #bar-status .mid .performance-percentage-value {
    border: 2px solid #ffe601;
  }
  #bar-status .low .performance-percentage-value {
    border: 2px solid #f21818;
  }
  #bar-status .min .performance-percentage-value {
    border: 2px solid #da0d0d;
  }
  .barCount {
    border: 2px solid #dedede;
    padding: 0.5rem 0.1rem;
    border-radius: 200px;
    font-weight: 700;
    width: 64px;
    background: #f4f4f4;
    color: #626262;
    font-size: 13px;
  }

  .smart-advice-container {
    margin-top: 10px !important;
    min-height : 600px;
  }

  .recommendation-col {
    border: 2px solid #000000;
  }

  .recommendations-page-title {
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 17px !important;
    text-align: left !important;

  }

  .recommendations-title {
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 17px !important;
    text-align: left !important;
  }

  .recommendations-subtitle {
    font-family: $regularFont, serif !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 15px !important;
    text-align: left !important;
  }
  .horizontal-hr-recommendation {
    border-top: 1px solid #D9D9D9 !important;
    opacity: 1 !important;
    width: 95% !important;
  }

  .get-started-button {
    background-color: #0511F2 !important;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    width: 35%;
    border-radius: 5px !important;
  }
  ///////////////////////////////////////////
  .recommendation-card {
    border: 1px solid #00000029 !important;
    border-radius: 4px !important;
    padding: 16px !important;
    gap: 16px !important;
  }

  .smart-advice-datatable-div {
    /*background-color: $whiteColor!important;*/
    margin-top: 25px !important;
  }

  .smart-advice-datatable-div .p-datatable-header {
    background-color: $whiteColor !important;
  }

  .smart-advice-datatable-div .p-datatable-wrapper table {
    border: 1px solid #D1D1D1 !important;
  }

  .smart-advice-datatable-div .p-datatable-wrapper table thead th, td {
    background-color: $whiteColor !important;
    border-bottom: 1px solid #D1D1D1 !important;
    font-family: $lightFont, serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 15px !important;
    letter-spacing: 0em !important;
    text-align: left !important;

  }

  .smart-advice-datatable-div .p-datatable-wrapper table thead th {
    border: 2px solid #F6F6F6 !important;
  }

  .smart-advice-datatable-div .p-datatable-wrapper table tbody td {
    border: 2px solid #F6F6F6 !important;
  }

  .smart-advice-datatable-div .p-datatable-header {
    border: none !important;
  }

  .smart-advice-datatable-div .p-paginator {
    border: 1px solid #F6F6F6 !important;
    border-top: none !important;
  }

  .smart-advice-datatable-div .p-column-header-content {
    justify-content: space-between;
  }

  .dismiss-link {
    font-family: $boldFont, serif !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.7px;
    text-align: left;
    color: $primaryColor;
    text-decoration: auto;
  }

  .action-menu ul {
    padding: 0 !important;
  }

  .action-menu ul li {
    font-family: $boldFont, serif !important;
    font-size: $size20;
    font-weight: 400;
    line-height: 24.7px;
    text-align: left;
    color: $primaryColor !important;

  }

  .action-menu ul li div a {
    text-decoration: auto;
  }


  .confirmation-modal-content {
    border: none !important;
    padding: 50px 40px;
  }

  .recommendations-title-red {
    color: #F21818
  }

  .recommendations-title-green {
    color: #29D683
  }

  .logout .custom-select__control {
    padding: 5px 14px;
    background-color: transparent
  }

  .code-container {
    background-color: #2d2d2d; /* Dark background color */
    color: #f8f8f2; /* Light text color for contrast */
    padding: 10px;
    border-radius: 4px;
    position: relative;
    width: 100%;

  }

  .code-container code {
    font-family: $regularFont, serif !important;

  }

  .copy-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: $size20;
    /* Add styles for your icon here */
  }
  .script-description {
    width: 80%;
  }

  .download-script-button {
    padding: 0px 8px !important;
    background-color: #0511f2 !important;
    font-family: "TTSupermolot-Bold", serif !important;
    text-transform: none !important;
    font-size: 15px !important;
    border-radius: 10px;
    color: #ffffff;
    height: 35px !important;
  }

  .script-card {
    width: 95% !important;
  }

  .script-container {
    margin-left: 50px !important;
    margin-right: 50px !important;
    width: 95% !important
  }

  .script-title {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
  }

  .scripts-accordion a {
    text-decoration: none;
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    color: $blackColor !important;
  }

  .custom-calendar {
    display: flex;
    justify-content: center;
    padding: $size20;
    border-bottom: 1px solid #ddd;
  }

  .predefined-ranges {
    display: flex;
    flex-direction: column;
    margin-right: $size20;
  }

  .range-button {
    padding: 5px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
    cursor: pointer;
    outline: none;
    font-size:10px!important;
  }

  .range-button:hover,
  .range-button.active {
    background-color: $primaryColor!important;
    color: #ffffff!important;
  }

  .date-range {
    display: flex;
  }

  .calendar-container {
    height: 400px; /* Adjust the height to fit two months */
    overflow-y: scroll;
  }

  .filters-date-picker {
    margin-right: 10px !important;
    font-family: $regularFont, serif !important;
    font-size: 25px !important;
    height: 0 !important;
    /*color:rgba(0, 0, 0, 0.5);*/
  }

  .filters-date-picker .dropdown-menu {
    width: 470px;

  }

  .filters-date-picker .dropdown-toggle {
    background-color: $whiteColor !important;
    color: $blackLightColor !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 5px !important;
    bottom: 35px;
    height: 40px;

  }

  .filters-date-picker .dropdown-toggle:after {
    display: none;

  }

  .filters-date-picker button svg {
    width: 25px;
    height: 25px;
  }

  .custom-calendar-apply-button {
    padding: 0px 5px !important;
    background-color: #0511f2 !important;
    font-family: "TTSupermolot-Bold", serif !important;
    text-transform: none !important;
    font-size: 12px !important;
    border-radius: 10px;
    color: #ffffff;
    border-radius: 5px !important;
    bottom: 35px;
    height: 35px;

  }

  .custom-calendar-cancel-button {
    padding: 0px 5px !important;
    background-color: $secondaryColor !important;
    font-family: "TTSupermolot-Bold", serif !important;
    text-transform: none !important;
    font-size: 12px !important;
    border-radius: 10px;
    color: #ffffff;
    border-radius: 5px !important;
    bottom: 35px;
    height: 35px;

  }

  .custom-calendar .rdrInRange {
    color: #0511f2 !important;
  }

  .custom-calendar .rdrStartEdge {
    color: #0511f2 !important;
  }

  .custom-calendar .rdrEndEdge {
    color: #0511f2 !important;
  }

  .filters-input:disabled {
    background-color: #ffffff !important;
  }

  .circle-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px; /* Circle size */
    height: 30px; /* Circle size */
    border-radius: 50%; /* Makes it circular */
    background-color: #f0f0f0; /* Background color of the circle */
    border: none; /* No border */
    cursor: pointer; /* Cursor changes to pointer when hovering over the button */
  }

  /* Optional: Add some styles for the icons inside the buttons if needed */
  .circle-button .datatable-icon,
  .circle-button .datatable-icon-chevrons {
    font-size: 15px; /* Icon size */
    color: rgba(0, 0, 0, 0.5);
  }

  .import-customer-container {
    margin: 10px;
    padding: inherit;
  }

  .upload-container {
    width: 70%;
    margin: 50px auto;
    text-align: center;
  }
  .dropzone {
    border: 2px dashed #cccccc;
    border-radius: 5px;
    padding: $size20;
    cursor: pointer;
    margin-bottom: $size20;
  }
  .excel-upload-input {
    width: 100%;
    height: 50px;
    border: none;
  }
  .import-customer-button {
    padding: 0px 8px !important;
    background-color: #0511f2 !important;
    font-family: "TTSupermolot-Bold", serif !important;
    text-transform: none !important;
    font-size: 15px !important;
    border-radius: 10px;
    color: #ffffff;
    bottom: 35px;
    height: 50px;
    width: 50%
  }
  .response {
    margin-top: $size20;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
  }

  .import-customer-title {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
  }


  .create-recommendation-button {
    background-color: rgba(5, 17, 242, 1) !important;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 22.23px !important;
    height: 40px !important;
    border-radius: 5px
  }

  .create-recommendation-button-link {
    float: right;
  }

  .create-recommendation-title {
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 30.75px !important;
    color: $blackColor;
    margin-bottom: 25px !important;
    margin-left: 10px !important;
    display: inline-block;
  }


  .create-recommendation-label {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 30.75px !important;
    color: $blackColor;
    margin-bottom: 25px !important;
    margin-left: 10px !important;
    display: inline-block;
  }


  .create-recommendation-input input {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 24.6px !important;
    width: 90% !important;
    padding: 10px 15px !important;
  }


  .create-recommendation-input textarea {
    font-family: $lightFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 24.6px !important;
    width: 90% !important;
    //padding: 10px 15px !important;
  }

  .recommendations-table th {
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    padding: $size20 15px !important;
  }

  .recommendations-table th div {
    cursor: pointer;
  }

  .recommendations-table td {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    padding: 10px 15px !important;

  }

  .recommendations-table {
    width: 95% !important;

  }

  .create-recommendation-select {

    font-family: $regularFont, serif !important;
    font-size: 22px !important;
    font-weight: 400;
    line-height: 27.06px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    width: 100% !important;
    float: right
  }

  .create-recommendation-select .custom-select__control {
    border-style: none;
    padding: 10px 0 !important;
  }

  .create-recommendation-select .custom-select__control--is-focused {
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .delete-recommendation-button {
    background-color: $secondaryColor !important;
  }

  .edit-recommendation-button {
    background-color: $primaryColor !important;
  }


  .products-table th {
    font-family: $boldFont, serif !important;
    font-size: 17px !important;
    font-weight: 400;
    line-height: 24.6px !important;
    padding: 10px 7px !important;
  }

  .products-table th div {
    cursor: pointer;
  }

  .products-table td {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 24.6px !important;
    padding: 5px 7px !important;

  }

  .products-table {
    width: 95% !important;

  }

  .email-stepper-button {
    color: $whiteColor !important;
    background-color: $primaryColor;
    font-family: $regularFont, serif !important;
    font-size: 12px !important;
    line-height: 12px !important;
    border-radius: 10px !important;
    border-color: #D1D1D1;
    margin: 24px 10px;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: $primaryColor !important;
  }

  .MuiStepIcon-root.MuiStepIcon-completed {
    color: $primaryColor !important;
  }


  .email-conten-preview-div {
    background-color: $whiteColor !important;
    width: 100% !important;
    margin-bottom: 15px !important;
    border: 1px solid #D1D1D1 !important;
    border-radius: 10px;
  }
  .google-tabview {
    background: transparent !important;
  }

  .home-tabview .p-tabview-panels {
    padding: 0 !important;
    background: transparent !important;
  }

  .connect-button-home {
    background: transparent;
    border: 0;
  }
  //////////////////////////////////////////// analytics ///////////////////////////
  .analytics-container {
    margin-top: 25px;
  }

  .coli3 {
    margin: auto;
    padding-bottom: 10px;
  }
  .analytics-small-card {
    height: auto !important;
    border-radius: 5px !important;
    width: 100% !important;
  }

  .analytics-small-card-body {
    text-align: center;
    padding: 10px 5px !important;
  }

  .analytics-small-card-body-typography {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 15px !important;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 10px !important;
  }

  .analytics-small-card-body-typography-number {
    font-family: $regularFont, serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 1);
    margin-top: 10px !important;
  }

  .analytics-small-card-body-typography-number span {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 10px !important;
  }

  .analytics-small-card-image {
    width: 80px;
    height: 80px;
  }

  .analytics-small-card-image-stock {
    width: 100px;
    height: 80px;
  }


  .analytics-big-card {
    height: auto !important;
    border-radius: 5px !important;
    width: 100% !important;
  }

  .analytics-big-card-body {
    text-align: center;
    padding: 10px !important;
  }

  .analytics-big-card-body-typography {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 15px !important;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 10px !important;
  }

  .analytics-big-card-body-typography-number {
    font-family: $regularFont, serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 1);
    margin-top: 10px !important;
  }

  .analytics-big-card-body-typography-number span {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 10px !important;
  }


  .analytics-table th {
    font-family: $boldFont, serif !important;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 17px !important;
    padding: 10px !important;
  }

  .analytics-table th div {
    cursor: pointer;
  }

  .analytics-table td {
    font-family: $regularFont, serif !important;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 17px !important;
    padding: 10px !important;

  }

  .analytics-table {
    width: 90% !important;

  }

  .analytics-mid-card {
    height: auto !important;
    border-radius: 5px !important;
    width: 100% !important;
  }

  .analytics-mid-card-body {
    text-align: left !important;
    padding: 10px !important;
  }

  .analytics-mid-card-body-typography {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 15px !important;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 10px !important;
  }

  .analytics-mid-card-body-typography-subtitle {
    font-family: $regularFont, serif !important;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 15px !important;
    color: #7FBA44;
    margin-top: 10px !important;
  }

  .analytics-mid-card-body-typography-subtitle-least {
    font-family: $regularFont, serif !important;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 15px !important;
    color: #ff6442;
    margin-top: 10px !important;
  }


  .analytics-mid-card-body-typography-number {
    font-family: $regularFont, serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 1);
    margin-top: 10px !important;
  }

  .analytics-mid-card-body-typography-number span {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 10px !important;
  }

  .analytics-carousel .p-carousel-indicators {
    display: none;
  }

  .analytics-see-all-button {
    font-family: $regularFont, serif !important;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.5);
    background: transparent;
    border: 0;
  }

  .analytics-see-all-button-small {
    font-family: $regularFont, serif !important;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.5);
    background: transparent;
    border: 0;
    position: absolute;
    bottom: 0;
    right: 75px;
  }

  .analytics-see-all-under-image {
    font-family: $regularFont, serif !important;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 19px !important;
    color: rgba(0, 0, 0, 0.5);
    background: transparent;
    border: 0;
    position: absolute;
    top: 80px;
    right: 5px;
  }

  .analytics-switch-button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background: #7FBA44;
    border-radius: 10px !important;
    border-color: #7FBA44;
  }

  .analytics-switch-button-least {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background: #ff6442;
    border-radius: 10px !important;
    border-color: #ff6442;
  }

  .analytics-background {
    background-color: white;
    border-radius: 14px;
    display: flex;
    height: 100px;
    padding: 10px;
  }

  .analytics-background-big {
    background-color: white;
    border-radius: 14px;
    display: flex;
    height: 100px;
    padding: 10px;
  }

  .analytics-customer-card-title {
    font-family: $regularFont, serif !important;
    font-size: 15px !important;
    font-weight: 400;
    line-height: 15px !important;
    color: #7FBA44;
    margin-top: 10px !important;
  }

  .analytics-frequency-button {
    color: #ffffff;
    border: 1px solid #7fba44;
    background: #7fba44;
    border-radius: 5px 15px 15px 5px;
    width: 100%;
    height: 100%;
  }

  .p-paginator{
    font-size:12px!important;
  }

  .p-paginator .p-dropdown{
    height:40px!important;
  }

  .p-paginator .p-dropdown .p-dropdown-label{
    font-size:12px!important;
  }

  .p-paginator .p-dropdown .p-dropdown-trigger svg{
    width:12px!important;
    height:12px!important;
  }

  .p-dropdown-items{
    padding:0!important;
  }
  .p-paginator-icon{
    width:12px!important;
    height:12px!important;
  }

  .recommendations-source-badge{
    background:$primaryColor!important;
    font-size:10px!important;
  }

  .calendar-container .rdrCalendarWrapper{
    font-size:10px!important;
  }
  .p-invalid {
    border: 1px solid #f44336!important;
  }


  /////////////////////////////////////////////////////////////////////automation/////////////////////////////////

  .automation-top-container {
    height: 60px !important;
    background-color: $whiteColor !important;
    margin-top: 7px !important;
  }

  .automation-top-container-typography {
    font-family: $regularFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400;
    line-height: 19px !important;
    color: $blackColor;
    padding: 18px 50px !important;
  }

  .automation-side-menu-container{
    position:absolute;
    right: 1.7rem !important;
    width: 275px !important;
    margin-top:15px!important;
    z-index:9
  }

  .automation-side-menu-parameter-container{
    position:absolute;
    left: 20% !important;
    width: 400px !important;
    margin-top:15px!important;
    z-index:9
  }

  .automation-flow-container{
    width:100%;
    height:100vh

  }

  .dndnode{
    height: 48px;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 2px;
    padding: 10px;
    margin-top: 5px;
    font-family: $regularFont, serif !important;
    font-size:16px
  }

  .react-flow__nodes .selected{
    background: #0511f2;
    border: 1px solid #0511f2;
    border-radius:1px;
  }

  .automation-side-menu .p-panel-header span{
    font-family: $boldFont, serif !important;
    font-size:17px
  }

  .automation-side-menu .p-panel-header{
    background-color:#ffffff!important;
  }

  .automation-side-menu{
    margin-bottom:15px!important;
  }

  .automation-side-menu-parameter .p-panel-header span{
    font-family: $boldFont, serif !important;
    font-size:17px
  }

  .automation-side-menu-parameter .p-panel-header{
    background-color:#ffffff!important;
  }

  .automation-side-menu-parameter{
    margin-bottom:15px!important;
  }

  .automation-top-buttons-div{
    //width:25%!important;
    align-items: center;
    margin-top: 5px;
  }

  .automation-top-cancel-button{
    color: $blackColor !important;
    background-color: #ffffff;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    border-color: #E2E2E2;
    margin-left: 25px;
    padding: 0 30px;
  }

  .automation-top-create-button{
    color: $whiteColor !important;
    background-color: $primaryColor;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    border-color: #D1D1D1;
    margin-left: 25px;
    padding: 0 30px;
  }

  .main-content-automation{
    background-color:#D1D1D14D!important;
  }

  .restore-div{
    z-index: 9999;
  }

  .automation-container {
    margin-top: 10px !important;
  }

  .automation-card {
     border: 1px solid #00000029 !important;
     border-radius: 4px !important;
     padding: 16px !important;
     gap: 16px !important;
    margin-bottom:10px
   }

  .automation-title {
    font-family: $boldFont, serif !important;
    font-size: 25px !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    text-align: left !important;
  }

  .automation-subtitle {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 15px !important;
    text-align: left !important;
  }

  .flow-image{
    width:auto!important;
    height:auto!important;
  }

  .automation-journey-title {
    font-family: $boldFont, serif !important;
    font-size: $size20 !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: left !important;
  }

  .journey-card{
    padding:15px !important;
    height:150px !important;
  }

  .journey-title {
    font-family: $boldFont, serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: left !important;
  }

  .journey-subtitle {
    font-family: $regularFont, serif !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    text-align: left !important;
  }



  .see-all-journey-button {
    background-color: #0511F2 !important;
    font-family: $boldFont, serif !important;
    font-size: 18px !important;
    line-height: 17px !important;
    height: 40px !important;
    border-radius: 5px !important;
    align-self: center;
  }


  .automation-journey-datatable-div {
  }

  .automation-journey-datatable-div .p-datatable-header {
    background-color: $whiteColor !important;
  }

  .automation-journey-datatable-div .p-datatable-wrapper table {
    border: 1px solid #D1D1D1 !important;
  }

  .automation-journey-datatable-div .p-datatable-wrapper table thead th, td {
    background-color: $whiteColor !important;
    border-bottom: 1px solid #D1D1D1 !important;
    font-family: $lightFont, serif !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 15px !important;
    letter-spacing: 0em !important;
    text-align: left !important;

  }

  .automation-journey-datatable-div .p-datatable-wrapper table thead th {
    border: 2px solid #F6F6F6 !important;
  }

  .automation-journey-datatable-div .p-datatable-wrapper table tbody td {
    border: 2px solid #F6F6F6 !important;
  }

  .automation-journey-datatable-div .p-datatable-header {
    border: none !important;
  }

  .automation-journey-datatable-div .p-paginator {
    border: 1px solid #F6F6F6 !important;
    border-top: none !important;
  }

  .automation-journey-datatable-div .p-column-header-content {
    justify-content: space-between;
  }




  .create-journey-modal-content {
    border: none !important;
    padding: 50px 30px;
    width: 600px !important;
  }
  .choose-template-modal-content {
    border: none !important;
    padding: 50px 30px;
    width: 100% !important;
  }


  .view-source-code-modal-content {
    border: none !important;
    padding: 50px 30px;
    width: 100% !important;
  }
  .create-journey-modal-title-typography {
    font-family: $boldFont, serif !important;
    font-weight: 400 !important;
    font-size: 25px !important;
    line-height: 49.4px;
    color: rgba(0, 0, 0, 1);
    margin-top: 15px !important;
    text-align: left !important;
  }



  .create-journey-modal-name-label {
    font-family: 'TTSupermolot-Bold', serif !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 37.05px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-top: 40px !important;
    margin-bottom: 15px !important;
  }


  .create-journey-modal-type-label {
    font-family: 'TTSupermolot-Bold', serif !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 37.05px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-top: 10px !important;
    margin-bottom: 15px !important;
  }



  .create-journey-modal-button {
    margin: 8px 0px !important;
    background-color: #0511f2 !important;
    margin-bottom: $size20 !important;
    font-family: $boldFont, serif !important;
    text-transform: none !important;
    font-size: $size20 !important;
  }

  .editable-node{
    border-radius: 1px;
    background-color: #fff;
    font-size:8px;
    padding:5px;
    width:auto;
    font-family: $boldFont, serif !important;
    border:1px dashed #B6B4B4;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circle-node{
    border-radius: 100%;
    background-color: #fff;
    border-color: #B6B4B4;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:10px
  }

  .node-handle{
    min-width:1px!important;
    min-height:1px!important;
    width:1px!important;
    height:1px!important;
    border:1px solid #000000!important;
  }

  .circle-node-label {
    position: absolute;
    top: 100%; /* Position it below the node */
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px; /* Adjust as needed */
    font-family: $regularFont, serif !important;
    font-size:8px;
    width: 150px;
  }
  .create-group-user-select {

    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-top: 10px !important;
    margin-bottom: 25px !important;
    width: 98% !important;
    float: left;
    height: 100px;
  }


  .create-group-user-select .custom-select__control {

    font-family: $regularFont, serif !important;
    border-style: none;
    margin-top: 10px !important;
  }

  .create-group-user-select .custom-select__control--is-focused {

    font-family: $regularFont, serif !important;
    border-style: none;
    border-color: $whiteColor transparent !important;
    box-shadow: none !important;
  }

  .create-group-user-select .p-multiselect-label {

    font-family: $regularFont, serif !important;
    display: flex !important;
    flex-wrap: wrap !important;
  }

  .nodes-panel{
    width: 175px;
    font-size:10px!important;
  }

  .nodes-panel .dndnode{
    font-size:10px!important;
    background-color: #FFFFFF;
    height: 20px;
  }

  .nodes-panel .expand-arrows{
    width:15px!important;
    height:15px!important;
  }


  .automation-parameter-input {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    margin-right: 25px !important;
    width: 100% !important;
    float: left;
    display: inline-block !important;
    height: 40px !important;
  }
  .automation-parameter-input .MuiInputBase-root {
    height: 100% !important;
    border-radius: 5px !important;
  }
  .automation-parameter-input input::placeholder {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 30.75px !important;
  }
  .automation-parameter-input input {
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
  }

  .if-else-icon-true{
   font-size: 5px!important;
    position: absolute !important;
    left:10%;
    bottom:-3px;
    background: #15c415;
    border-radius: 2px;
    color: white;
    padding: 1px 4px;
  }

  .if-else-icon-false{
   font-size: 5px!important;
    position: absolute !important;
    left:60%;
    bottom:-3px;
    background: $secondaryColor;
    border-radius: 2px;
    color: white;
    padding: 1px 4px;
  }

  .email-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2em;
    z-index: 1000;
  }

  .content.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .loading-email {
    color: #0511f2;

  }
  .automation-control-buttons-div{

  }

  .edit-automation-input{
    font-family: $regularFont, serif !important;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 17px !important;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    //margin-right: 25px !important;
    width: 100% !important;
    float: left;
    display: inline-block !important;
    height: 40px !important;
  }

  .edit-automation-input input{
    padding:5px 18px!important;
    font-family: $regularFont, serif !important;
    font-size: 16px !important;
  }


  .automation-top-toolbar-remove-button{
    height: 3rem !important;
    border-color: #ef4444;
    margin-left: 25px;
    border-radius:50%
  }

  .automation-top-toolbar-save-button{
    height: 3rem !important;
    border-color: #22c55e;
    margin-left: 25px;
    border-radius:50%
  }

  .automation-top-toolbar-restore-button{
    height: 3rem !important;
    border-color: #0ea5e9;
    margin-left: 25px;
    border-radius:50%
  }



  .analytics-product-description p {
    margin-bottom : 0!important;
  }


  .instruction{
    font-family: $regularFont, serif !important;
    font-size: 16px !important;
    color: $primaryColor;
  }

  .center-text .MuiInputBase-input {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 10px;
    padding-top : 10px;
    padding-bottom : 10px;
  }

  .center-text .MuiInputBase-input::placeholder {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 10px; /* Adjust padding as needed */
    padding-top : 10px;
    padding-bottom : 10px;
  }

  .report .p-tabview-panels{
    background:transparent;
  }

}